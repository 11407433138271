<template>
  <v-card class="mt-5">
    <v-toolbar
      dense
      flat
      dark
      color="secondary"
    >
      <v-spacer />
      <v-toolbar-title v-text="projectStartTitle" />
      <v-spacer />
    </v-toolbar>

    <v-card-text>
      <v-alert
        text
        prominent
        :color="userMessage.color"
        :icon="userMessage.icon"
      >
        <v-row no-gutters align="center" class="pl-5">
          <v-col cols="grow">
            <h3 class="headline" v-text="userMessage.title" />
            <span v-text="userMessage.subtitle" />
          </v-col>

          <v-col v-if="canStartProject" cols="auto" class="mx-1">
            <v-btn
              :loading="loadingDoesntStartProject"
              :disabled="isLoading"
              color="secondary"
              outlined
              @click="doesntStartProject"
              v-text="$t('multiproject.start.doesntStartProject')"
            />
          </v-col>

          <v-col v-if="canStartProject || mustStartProject" cols="auto" class="mx-1">
            <v-btn
              :loading="loadingStartProject"
              :disabled="isLoading"
              color="accent"
              @click="startProject"
              v-text="$t('multiproject.start.startProject')"
            />
          </v-col>
        </v-row>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import AVAILABLE_STATUS from '../availableStatus'
import AVAILABLE_ACTIONS from '../availableActions'

export default {
  name: 'StartContainer',
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data: () => ({
    loadingStartProject: false,
    loadingDoesntStartProject: false,
  }),
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    currentStatus () {
      return this.currentPlayer.status
    },
    projectStartTitle () {
      return this.$t('multiproject.start.title.startPlanned', {
        week: this.currentPlayer.weekStartPlanned,
      })
    },
    userMessage () {
      const blueResource = this.game.resourceList.find(item => item.color === 'blue')
      return {
        title: this.$t(`multiproject.start.${this.currentStatus}.title`, {
          week: this.game.currentRound,
          resource: blueResource.name,
        }),
        subtitle: this.$t(`multiproject.start.${this.currentStatus}.subtitle`),
        icon: this.$t(`multiproject.start.${this.currentStatus}.icon`),
        color: this.$t(`multiproject.start.${this.currentStatus}.color`),
      }
    },
    canStartProject () {
      return this.currentStatus === AVAILABLE_STATUS.canStartProjectBlueReady
    },
    mustStartProject () {
      return this.currentStatus === AVAILABLE_STATUS.mustStartProject
    },
    isLoading () {
      return this.loadingStartProject || this.loadingDoesntStartProject
    },
  },
  methods: {
    async startProject () {
      this.loadingStartProject = true
      await this.sendPlayerAction(AVAILABLE_ACTIONS.startProject)
      this.loadingStartProject = false
    },
    async doesntStartProject () {
      this.loadingDoesntStartProject = true
      await this.sendPlayerAction(AVAILABLE_ACTIONS.doesntStartProject)
      this.loadingDoesntStartProject = false
    },
  },
}
</script>
